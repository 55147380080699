import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import moment from 'moment'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Image from "../../../components/image"
import Helmet from "react-helmet"
import Review from "../../../components/review"
import Share from "../../../components/share"
import TweetWidget from "../../../components/tweetWidget"
import Campaign from "../../../components/campaign"
import Tag from "../../../components/tag"

const typeSizeBrandSubbrandPage = ({ data, location, pageContext: { type_id, size_id, brand_id, subbrand_id } }) => {
	const type = data.typesMilkYaml
	const size = data.sizesMilkYaml
	const brand = data.brandsMilkYaml
	const subbrand = data.subbrandsMilkYaml
	const sizeStr = '' 
	const itemStr = 'ミルク'
	const unitStr = '100ml'
	const category = 'milk'
	const categoryIndex = '/milk/milk_powder_can_big_all_all'
	const subbrandDetail = subbrand.types.find(n => {
		return n.type_id === type.cid
	}).sizes.find(n => {
		return n.size_id === size.cid
	})
	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment(data.contentfulItems.updatedAt).format('YYYY年M月D日')
	let cnt = 0
	let description = ``
	let preTitle = ``	
	let shop = ``
	if(data.contentfulItems.itemList.length){
		description = `${brand.description} ${subbrand.description} ${type.description} ${size.description}${sizeStr} ${unitStr}あたりの最安値は${data.contentfulItems.itemList[0].per.toFixed(1)}円。${data.contentfulItems.itemList[0].shop}で販売しています。`
		preTitle = `${data.contentfulItems.itemList[0].per.toFixed(1)}円/${unitStr}`	
		shop = `${data.contentfulItems.itemList[0].shop}`
	}else{
		description = `${brand.description} ${subbrand.description} ${type.description} ${size.description}${sizeStr} は販売している店舗がありません。`
		preTitle = ``	
		shop = ``
	}
	
	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": itemStr,
			"item": "https://hula-la.com" + categoryIndex
		},{
			"@type": "ListItem",
			"position": 3,
			"name": type.description,
			"item": "https://hula-la.com/"+category+"/"+type.cid+".html"
		},{
			"@type": "ListItem",
			"position": 4,
			"name": size.description,
			"item": "https://hula-la.com/"+category+"/"+type.cid+"_"+size.cid+".html"
		},{
			"@type": "ListItem",
			"position": 5,
			"name": brand.description,
			"item": "https://hula-la.com/"+category+"/"+brand.cid+"_"+type.cid+"_"+size.cid+".html"
		}]
	};

	// 商品の構造化データ
	const jsonLdProduct = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": brand.description+subbrand.description+" "+type.description+" "+size.description+sizeStr,
		"size": size.description,
		"description": brand.maker+"から発売されている"+brand.description+subbrand.description+" "+type.description+" "+size.description+sizeStr,
		"gtin13": data.contentfulItems.jan,
		"brand": {
			"@type": "Brand",
			"name": brand.maker
		},
		"offers": {
			"@type": "AggregateOffer",
			"offerCount": data.contentfulItems.itemList.length,
			"lowPrice": data.contentfulItems.itemList.length > 0 ? Math.min(...data.contentfulItems.itemList.map((p) => p.price)) : 0,
			"highPrice": data.contentfulItems.itemList.length > 0 ? Math.max(...data.contentfulItems.itemList.map((p) => p.price)) : 0,
			"priceCurrency": "JPY"
		}
	};

	return (
  <Layout>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				 <Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLdProduct)}</script>
   			</Helmet>
				<h2 id={`${subbrand.subbrand_id}`} className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{brand.description} {subbrand.description} {type.description} {size.description}{sizeStr}</h2>
				{/* <MostCheap item={data.contentfulItems} update={update}/> */}
        <TweetWidget urlPath={`https://hula-la.com${location.pathname}`} />				
				<h4 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>ランキング<small> ({update}更新)</small></h4>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>で{unitStr}あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
				<div className="columns is-multiline is-gapless">
					{data.contentfulItems.itemList.map(item => {
						const use = `100mlあたり${subbrandDetail.use}${subbrandDetail.unit}使用`
						const pack = item.num/subbrandDetail.num
						const num = `${subbrandDetail.num}${subbrandDetail.unit}×${pack}${subbrandDetail.pack_unit}=${item.num}${subbrandDetail.unit}`

						const sendPrice = item.sendPrice > 0 ? `送料${item.sendPrice}円` : item.sendPrice === 0 ? `送料無料` : item.sendPrice === -1 ? `実店舗` : '店舗受取';
						cnt += 1
						const order = cnt
						let price_org = ``
						if(item.txt){
							if(item.txt.match(/OFF/))
							{
								price_org = `${PriceKanma(item.price_org)}円`
							}
						}
						const filename = `${brand.cid}_${type.cid}_${size.cid}_${subbrand.subbrand_id}.jpg`
						return (
							<div className="column is-one-third">
								<a href={`${item.href}`} rel="nofollow"
									onClick={e => {
										trackCustomEvent({
											category: `${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`,
											action: `${item.shop}`,
											label: `${order}位`,
											transport: 'beacon'
										})
									}}									 
								>
									<div className="card">
										<div className="card-content" style={{padding: `0.8rem`}}>
											<div className="media">
												<div className="media-left">
												{ cnt !== 0 && (
													<span><strong>&nbsp;&nbsp;&nbsp;&nbsp;{order}<span style={{fontSize:`0.9rem`}}>位</span></strong></span>
												)}
	                      <Image filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
												{ cnt === 1 && (
													<span class="has-text-danger" style={{fontSize:`0.8rem`}}>&nbsp;&nbsp;&nbsp;<strong>最安値</strong></span>
												)}
												</div>
												<div className="media-content">
													<div className="content">
														<p style={{marginBottom: `0.2rem`}}>
															<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{unitStr}</span></span>
															<span style={{color: `#ff0000`}} className="is-size-7">&nbsp;&nbsp;{PriceKanma(item.price)}円<span style={{color: `#808080`}}>&nbsp;<strike>{price_org}</strike></span></span>&nbsp;
															<Tag item={item} />
															<br />
															{ 'point' in item && item.point > 0 && (
																<><span className="tag is-danger is-light" style={{fontSize:`0.6rem`}}><b>{item.point}ポイント</b></span>&nbsp;</>
															)}
															<span className="tag" style={{fontSize:`0.6rem`}}>{sendPrice}</span>&nbsp;
															<span className="tag" style={{fontSize:`0.6rem`}}>{num}</span>&nbsp;
															{ type.cid === "milk_powder" && (
																<><span className="tag" style={{fontSize:`0.6rem`}}>{use}</span>&nbsp;</>
															)}                          
															<br />
															<span style={{color: `#0783c2`}} className="is-size-7">&nbsp;&nbsp;{item.shop}</span>
															<Review item={item} category={`${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`}/>
														</p>
														<Campaign item={item} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</a>
							</div>
							)
						})}
				</div>
				<Share articleUrl={`https://hula-la.com${location.pathname}`} articleTitle={`【${preTitle}】${brand.description} ${subbrand.description} ${type.description} ${size.description}`} />
				<div className="content is-small">
					<h4 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>詳細</h4>
						<div className="columns">
							<div className="column is-4">
							</div>
							<div className="column is-7 is-offset-1">
								<table className="table">
									<tbody>
										<tr>
											<td className="has-text-right">
												<strong>メーカー</strong>
											</td>
											<td>{brand.maker}</td>
										</tr>
										<tr>
											<td className="has-text-right">
												<strong>製品名</strong>
											</td>
											<td>{brand.description}{subbrand.description}</td>
										</tr>
										<tr>
										<td className="has-text-right">
											<strong>タイプ</strong>
										</td>
										<td>{type.description}</td>
									</tr>
									<tr>
										<td className="has-text-right">
											<strong>1{subbrandDetail.pack_unit}の量</strong>
										</td>
										<td>{subbrandDetail.num}{subbrandDetail.unit}</td>
									</tr>
									<tr>
										<td className="has-text-right">
											<strong>100mlあたりの使用量</strong>
										</td>
										<td>{subbrandDetail.use}{subbrandDetail.unit}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<br></br>
			</>
		<Seo title={`${brand.description} ${subbrand.description} ${type.description} ${size.description}`} description={description} canonical={location.pathname} />
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`${categoryIndex}`}>{itemStr}</Link></li>
				<li><Link to={`/${category}/${type.cid}.html`}>{type.description}</Link></li>
				<li><Link to={`/${category}/${type.cid}_${size.cid}.html`}>{size.description}</Link></li>
				<li><Link to={`/${category}/${brand.cid}_${type.cid}_${size.cid}.html`}>{brand.description}</Link></li>
			</ul>
		</nav>
  </Layout>
	)
}

export const query = graphql`
	query($brand_id: String! $type_id: String! $size_id: String! $subbrand_id: String!) {
		brandsMilkYaml(cid: { eq: $brand_id}) {
			cid
			description
			maker
		}
		sizesMilkYaml(cid: { eq: $size_id}) {
			cid
			description
		}
		typesMilkYaml(cid: { eq: $type_id}) {
			cid
			description
		}
		allTypesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		subbrandsMilkYaml(brand_id: { eq: $brand_id} subbrand_id: { eq: $subbrand_id}) {
			brand_id
			subbrand_id
			description
			types {
				type_id 
				sizes {
					size_id
					num	
					unit
					use
					pack_unit
				}
			}
		}
		allSubbrandsMilkYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
							unit
							use
							pack_unit
						}
					}
				}
			}
		}
		contentfulItems(brandId: { eq: $brand_id} typeId: { eq: $type_id} sizeId: { eq: $size_id} subbrandId: { eq: $subbrand_id}) {
			jan
			brandId
			subbrandId
			typeId
			sizeId
			itemList {
				num
				shopId
				shop
				href
				per
				per_org
				price
				price_org
				sendPrice
				souryou
				txt
				point
				star
				review
				reviewUrl
			}
			updatedAt
		}
	}
`

export default typeSizeBrandSubbrandPage

function PriceKanma(_price){
	var price = _price.toString(10);
	var length = price.length;
	var price_kanma;
	if(length > 3)
	{
		price_kanma = price.slice(0,length-3) + "," + price.slice(length-3);
	}
	else
	{
		price_kanma = price;
	}
	return price_kanma;
}